import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../styles/styles.css"
import "../styles/layout-styles.css"
import "../styles/custom-styles.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-5">
        <div class="format_text">
          <p style={{ width: "100%", marginTop: "10px" }}>
            LARRY TRAUTMAN is author and teacher of public policy, business law,
            climate, constitutional law, cyber security, entrepreneurship,
            ethics and corporate governance scholarship. Educator, heavily
            published, nationally-recognized, proven entrepreneur (responsible
            for about a dozen company start-ups) and professional corporate
            director. Invited speaker or full-time faculty at: University of
            Florida; George Mason University; George Washington University;
            Harvard University; UC Davis School of Law; Western Carolina
            University (2016-2018); Prairie View A&M University, the first HBCU
            in Texas (2019 - Present) and others.
          </p>
          <p>
            He has many years experience as a professional corporate director
            with particular expertise in the areas of corporate governance,
            investment banking, law, and capital formation. Mr. Trautman held
            numerous corporate directorships in publicly-traded and
            privately-owned corporations engaged in such diverse industries as
            dairy processing, financial services, franchising and fast food
            (Custom Creamery, Orange Julius and Crescott, Inc.– all NASDAQ),
            mortgage banking, publishing, software and information technology,
            oil &amp; gas (Comstock Resources, Inc., NYSE: CRK), and others. He
            served as Chairman of the Committee of Independent Directors in the
            sale of Orange Julius, Inc. to International Dairy Queen (now owned
            by Warren Buffett’s{" "}
            <a href="http://www.berkshirehathaway.com/">
              Berkshire Hathaway, Inc
            </a>
            . As Senior Vice President of the New York-based investment banking
            firm Donaldson, Lufkin &amp; Jenrette, he had a diverse corporate
            finance practice, having sole client responsibility for the
            origination, negotiation and processing of billions of dollars
            (current market value) of merger and acquisition transactions and
            public equity offerings for both traded and going-public situations.
            His other transaction experience includes: venture capital, real
            estate, public finance, and private debt and equity placement. He is
            a serial entrepreneur, bringing skill sets and experience to his
            practice of corporate governance.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
